<template>
  <div>
    <el-card v-for="x in count" class="mb-1" :key="x">
      <el-skeleton animated>
        <template slot="template">
          <div style="display: flex; align-items: center; ">
            <el-skeleton-item variant="image" style="width: 32px; height: 32px" />
            <el-skeleton-item variant="text" class="ml-1" />
          </div>
        </template>
      </el-skeleton>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'UserNameCardSkeleton',
  props:{
    count:{
      default: 1,
      type: Number
    }
  }
}
</script>

<style scoped>

</style>