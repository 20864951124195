<template>
  <div class="widgetContainer widgetContainer--scrollable">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          Managers
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-card v-if="$store.getters['user/isPiloting']" class="text-center">
        <div class="mb-2 font-bold">
          You are logged in as a Manager of <br>
          <span class="font-20">{{ $store.getters['user/getPersonDetails'].email }}</span>
        </div>
        <span>To manage your own managers please re-login using your default account.</span>
      </el-card>
      <template v-else>
        <template v-if="loading">
          <UserNameCardSkeleton :count="3" />
        </template>
        <el-card v-else v-for="manager in $store.getters['dashboard/getManagers']" :key="manager.id" class="person_card mb-1" @click.native="selectManager(manager)">
          <div class="initialsInfoContact initialCaps">
            <span>{{ manager.user.name.charAt(0) }}</span>
          </div>
          <div class="details">
            <p class="name initialCaps">
              {{ manager.user.name }}
            </p>
          </div>
          <span class="icon-Arrow-small-right icon" />
        </el-card>
      </template>
    </div>
    <div class="widgetContainer__footer--fixed">
      <div class="px-2">
        <el-button v-if="!$store.getters['user/isPiloting']" class="brand" type="primary" @click="drawerPush('AddManager')">
          Add Manager
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UserNameCardSkeleton from '@/components/Skeleton/UserNameCardSkeleton';
export default {
  name: 'Managers',
  components: {UserNameCardSkeleton},
  data(){
    return {
      loading: true
    }
  },
  async created() {
    await this.$store.dispatch('dashboard/fetchManagers')
    this.loading = false
  },
  methods:{
    selectManager(manager){
      this.$store.commit('dashboard/setManager',manager);
      this.drawerPush('EditManager');
    }
  }
}
</script>

<style lang="scss">
.person_card {
  .el-card__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    transition: 0.2s;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;

    .initialsInfoContact {
      background: #f6f7f9;
      color: var(--branding-primary);
    }

    .details {
      margin: 0 auto 0 15px;
      padding-right: 8px;

      .name {
        font-size: 15px;
        line-height: 20px;
      }

      .date {
        color: rgba(#3c3c43, 0.6);
        font-size: 12px;
        padding-top: 2px;
      }
    }

    .icon {
      color: #c7c7c9;
      font-size: 13px;
    }
  }
}

</style>